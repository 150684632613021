import { ChangeDetectionStrategy, Component, OnDestroy, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoService } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { AVAILABLE_LANGS, CURRENT_LANG_KEY, Language, LocalStorageService } from '@volago/core';
import { Router } from '@angular/router';

@Component({
  selector: 'volago-language-selector',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule],
  templateUrl: './language-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnDestroy {

  translationService = inject(TranslocoService)
  localStorageService = inject(LocalStorageService);
  router = inject(Router);

  flagsPath = input<string>('/assets/images/flags/');
  langs = AVAILABLE_LANGS;

  activeLang = this.translationService.getActiveLang();
  activeFlagUrl = '';
  activeFlagDescription = '';

  private langChangeSub = this.translationService.langChanges$.subscribe((lang: string) => {
    this.activeFlagUrl = this.flagsPath() + lang + '.svg';
    this.activeFlagDescription = this.langs.find((l) => l.id === lang)?.alt ?? '';
  });

  selectLanguage(lang: Language): void {
    this.translationService.setActiveLang(lang.id);
    this.localStorageService.set(CURRENT_LANG_KEY, lang.id);

    this.router.navigate([lang.id, ...this.router.url.split('/').slice(2)]);
  }

  ngOnDestroy(): void {
    this.langChangeSub.unsubscribe();
  }
}