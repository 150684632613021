import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@volago/core';
import { Hours } from '../../hours';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'volago-attraction-hours-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, DialogComponent, MatButtonModule],
  templateUrl: './hours-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoursDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<HoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public hours: Hours
  ) { }

  close(): void {
    this.dialogRef.close();
  }

}
