@if (vm$ | async; as vm) {
  <div class="flex" 
       (click)="showInfoPanel(vm.attraction.id)" 
       (keydown.enter)="showInfoPanel(vm.attraction.id)"
       tabindex="0" 
       role="button" 
       [attr.aria-label]="'Show info panel for ' + vm.attraction.name">
    <img [ngSrc]="vm.photoPath" 
         width="100" 
         height="150" 
         [loaderParams]="{crop: 'fill', width: 100, height: 150}"
         [alt]="'Photo of ' + vm.attraction.name" />
    <div class="grow flex flex-col m-2">
      <span class="text-xl mb-2">{{ vm.attraction.name }}</span>
      <volago-importance [score]="vm.attraction.importance"></volago-importance>
    </div>
  </div>
}