import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Attraction } from './attraction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttractionFirestoreService } from './attraction-firestore.service';
import { AttractionFirestoreData } from './attraction-firestore-data';
import { HoursDataService } from '../hours/hours-data.service';

@Injectable({
  providedIn: 'root',
})
export class AttractionDataService {
  constructor(
    private firestoreService: AttractionFirestoreService,
    private hoursDataService: HoursDataService
  ) {}

  get(id: string): Observable<Attraction | undefined> {
    return this.firestoreService.get(id).pipe(
      map(attractionData => attractionData ? this.convertTimestampsToDates(attractionData) : undefined)
    );
  }

  getAll(): Observable<Attraction[]> {
    return this.firestoreService.getAll().pipe(
      map(attractionsData => attractionsData.map(attractionData => this.convertTimestampsToDates(attractionData)))
    );
  }

  add(attraction: Attraction): Promise<void> {
    return this.firestoreService.add(this.convertDatesToTimestamps(attraction));
  }

  save(attraction: Attraction): Promise<void> {
    return this.firestoreService.save(this.convertDatesToTimestamps(attraction));
  }

  private convertTimestampsToDates(attractionData: AttractionFirestoreData): Attraction {
    return {
      ...attractionData,
      createDate: attractionData.createDate.toDate(),
      modifyDate: attractionData.modifyDate.toDate(),
      hours: this.hoursDataService.convertTimestampsToDates(attractionData.hours),
    };
  }

  private convertDatesToTimestamps(attraction: Attraction): AttractionFirestoreData {
    return {
      ...attraction,
      createDate: Timestamp.fromDate(attraction.createDate),
      modifyDate: Timestamp.fromDate(attraction.modifyDate),
      hours: this.hoursDataService.convertDatesToTimestamps(attraction.hours),
    };
  }
}
