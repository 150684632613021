@if (hours().closed) {
<div>
  <span>{{caption()}} </span>
  <span>nieczynne</span>
</div>
} @else {
<div>
  <span>{{caption()}} </span>
  <span>{{hours().from}}</span>
  <span> - </span>
  <span> {{hours().to}}</span>
</div>
}
