@if(userLoggedIn) {
  <button mat-mini-fab [matMenuTriggerFor]="menu" color="accent"
    aria-label="Icon button with a menu icon">
    {{ userFirstLetter }}
  </button>
} @else {
  <button mat-mini-fab (click)="openLoginDialog()" color="primary"
    aria-label="Icon button with a menu icon">
    <span class="material-symbols-outlined">person</span>
  </button>
}

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/pl-PL/profile']">Profil</button>
  <button mat-menu-item (click)="logout()">Wyloguj</button>
</mat-menu>