import { Injectable, effect, signal } from '@angular/core';
import { map } from 'rxjs';
import { marker, icon, Marker } from 'leaflet';
import { MarkerDataService, VolagoMarker } from '@volago/core';
import { GlobalEventsService } from '../../../shared/global-events/global-events.service';

@Injectable({
  providedIn: 'root',
})
export class MarkerService {

  private markers = signal<MarkerWrapper[]>([]);

  constructor(
    private dataService: MarkerDataService,
    private globalEvents: GlobalEventsService
  ) {
    this.initialize();

    effect(() => {
      const m = this.globalEvents.selectedMarker();
      if (m) {
        this.selectMarker(m);
      }
      else {
        this.deselectAll();
      }
    }, { allowSignalWrites: true });
  }

  getLeafletMarkers(): Marker<unknown>[] {
    const result = this.markers().map(x => x.leafletMarker);
    return result;
  }

  getMarker(id: string): MarkerWrapper {
    const result = this.markers().find(x => x.id === id);
    return result;
  }

  private initialize(): void {
    this.dataService
      .getAll()
      .pipe(
        map((ms) => {
          const markers = ms.map(m => {
            const markerL = this.createLeafletMarker(m);
            return { ...m, leafletMarker: markerL };
          });
          return markers;
        })
      )
      .subscribe(res => {
        this.markers.set(res);
      });
  }

  private createLeafletMarker(m: VolagoMarker): Marker {
    return marker([m.coords.latitude, m.coords.longitude], {
      icon: NOT_SELECTED_ICON,
    }).on('click', () => this.globalEvents.select(m.id));
  }

  deselectAll(): void {
    this.markers.update(ms => {
      ms.forEach(m => m.leafletMarker.setIcon(NOT_SELECTED_ICON));
      return ms;
    });
  }

  public selectMarker(id: string): void {
    this.deselectAll();
    this.markers.update(ms => {
      ms.find(m => m.id === id).leafletMarker.setIcon(SELECTED_ICON);
      return ms;
    });
  }
}

const SELECTED_ICON = icon({
  iconSize: [26, 26],
  iconAnchor: [13, 13],
  iconUrl: 'assets/images/map/marker-on.svg',
});

const NOT_SELECTED_ICON = icon({
  iconSize: [26, 26],
  iconAnchor: [13, 13],
  iconUrl: 'assets/images/map/marker-off.svg',
});

export interface MarkerWrapper extends VolagoMarker {
  leafletMarker: Marker;
}
