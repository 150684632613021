import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoreCardViewModel } from '../score-card-view-model';

@Component({
  selector: 'volago-score-card-dialog',
  templateUrl: './score-card-dialog.component.html',
})
export class ScoreCardDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<ScoreCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScoreCardViewModel
  ) { }

  close(): void {
    this.dialogRef.close();
  }

}
