import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { DataService } from '@volago/core';
import { AttractionFirestoreData } from './attraction-firestore-data';

@Injectable({
  providedIn: 'root',
})
export class AttractionFirestoreService extends DataService<AttractionFirestoreData> {
  constructor(firestore: Firestore) {
    super(firestore, 'attractions');
  }
}