import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalEventsService {

  selectedMarker = signal<string>(null);

  select(m: string): void {
    this.selectedMarker.set(m);
    this.detectChanges();
  }

  // TODO: WORKAROUND !!!! Currently effects are hooked to change detection. 
  // This workaround force change detection by AppComponent.
  private detectChanges: () => void;
  onSomethingHappended(fn: () => void) {
    this.detectChanges = fn;
  }
}