import { ChangeDetectorRef, Component } from '@angular/core';
import { environment } from '../environments/environment';
import { GlobalEventsService } from './shared/global-events/global-events.service';
import { Router } from '@angular/router';
import { CURRENT_LANG_KEY, LocalStorageService } from '@volago/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'volago-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'volago';
  stage = environment.stage;

  constructor(
    private events: GlobalEventsService,
    private chr: ChangeDetectorRef,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translocoService: TranslocoService
  ) {
    const href = window.location.href;
    const attractionId = href.substring(href.lastIndexOf('/') + 1);

    this.events.onSomethingHappended(this.runChangeDetection.bind(this));
    this.navigateToLocalizedMap(attractionId);
  }

  private navigateToLocalizedMap(id: string): void {

    if (id === 'map') {
      id = '';
    }

    let lang = 'pl-PL';

    const userLanguage = navigator.language;
    if (userLanguage) {
      lang = userLanguage;
    }

    const persistedLang = this.localStorageService.get<string>(CURRENT_LANG_KEY);
    if (persistedLang) {
      lang = persistedLang;
    }

    this.translocoService.setActiveLang(lang);
    this.router.navigate([lang, 'map', id]);
  }

  private runChangeDetection(): void {
    this.chr.detectChanges();
  }

}