import { Component, inject } from '@angular/core';
import { MediaService } from '@volago/core';

@Component({
  selector: 'volago-map-container',
  templateUrl: './map-container.component.html',
})
export class MapContainerComponent {

  mediaService = inject(MediaService);

}
