<volago-map mapId="volago-main-map" [leafletLayers]="this.markerService.getLeafletMarkers()"
    (leafletMapReady)="onMapReady($event)" (leafletClick)="onMapClicked()"></volago-map>

<div id="bottom-panel-wrapper" *ngIf="(selected$ | async) && !mobileExpanded" class="mat-app-background">
    <volago-mobile-info-panel-bottom [attractionId]="selected$ | async" (openPanel)="openMobileInfoPanel($event)">
    </volago-mobile-info-panel-bottom>
</div>

<div id="panel-wrapper" *ngIf="(selected$ | async) && mobileExpanded">
    <volago-info-panel [attractionId$]="selected$" (closed)="closeMobileInfoPanel()"></volago-info-panel>
</div>