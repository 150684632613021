import { Environment } from '@volago/core';

export const environment: Environment = {
  production: false,
  stage: '',
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
  },
  urls: {
    tobjectUrl: '',
  },
  cloudinary: {
    cloudName: '',
    imageBaseUrl: '',
  },
};
