export interface AttractionRating {
  id: string;
  userId: string;
  attractionId: string;
  stars: number;
  favorite: boolean;
  wantToVisit: boolean;
  hidden: boolean;
}

export const EMPTY_ATTRACTION_RATING: AttractionRating = {
  id: '',
  userId: '',
  attractionId: '',
  stars: 0,
  favorite: false,
  wantToVisit: false,
  hidden: false, // icon visibility_off
}