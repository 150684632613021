import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapContainerComponent } from './map/map-container.component';
import { InfoPanelModule } from '../info-panel/info-panel.module';
import { RouterModule } from '@angular/router';
import { MapMobileComponent } from './map/mobile/map-mobile.component';
import { MapDesktopComponent } from './map/desktop/map-desktop.component';
import { CoreModule } from '@volago/core';
import { UserMenuComponent } from '../auth/user-menu/user-menu.component';
import { LanguageSelectorComponent, ThemePickerComponent } from '@volago/layout';

const routes = [
  {
    path: ':lang/map',
    component: MapContainerComponent,
    data: {
      shouldReuse: true,
      key: 'map'
    }
  },
  {
    path: ':lang/map/:id',
    component: MapContainerComponent,
    data: {
      shouldReuse: true,
      key: 'map'
    }
  },
]

@NgModule({
  declarations: [MapContainerComponent, MapMobileComponent, MapDesktopComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    InfoPanelModule,
    CoreModule,
    UserMenuComponent,
    ThemePickerComponent,
    LanguageSelectorComponent
  ],
  exports: [
    MapContainerComponent,
  ]
})
export class MapModule { }
