import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Pricing } from '../pricing';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'volago-attraction-pricing',
  templateUrl: './pricing.component.html',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingComponent {

  data = input.required<Pricing>();
  currency = computed(() => this.data().currency);

}
