import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RouterModule, RouteReuseStrategy, Route, provideRouter, withComponentInputBinding } from '@angular/router';
import { MapModule } from './feature/map/map.module';
import { InfoPanelModule } from './feature/info-panel/info-panel.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CacheRouteReuseStrategy } from './shared/cache-route-reuse.strategy';
import { CLOUDINARY_OPTIONS, ConfigAssetLoaderService, TranslocoRootModule, cloudinaryCustomLoaderFactory } from '@volago/core';
import { environment } from '../environments/environment';
import { CommonModule, IMAGE_LOADER } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AuthGuard, AuthGuardModule } from '@angular/fire/auth-guard';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { ThemeManagerService } from '@volago/layout';

export const routes: Route[] = [
    {
        path: ':lang/profile',
        loadChildren: () => import('@volago/profile').then(x => x.ROUTES_PROFILE),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [AppComponent],
    exports: [RouterModule],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule,
        CommonModule,
        SharedModule,
        AuthGuardModule,
        TranslocoRootModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => {
            const auth = getAuth();
            // if (environment.useEmulators) {
            //   connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            // }
            return auth;
        }),
        MapModule,
        InfoPanelModule,
        BrowserAnimationsModule],
    providers: [
        provideRouter(routes, withComponentInputBinding()),
        {
            provide: RouteReuseStrategy,
            useClass: CacheRouteReuseStrategy,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigAssetLoaderService) => () => configService.loadEnvironment(environment),
            deps: [ConfigAssetLoaderService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ThemeManagerService) => () => configService.load(),
            deps: [ThemeManagerService],
            multi: true,
        },
        {
            provide: MATERIAL_SANITY_CHECKS,
            useValue: {
                theme: false
            }
        },
        {
            provide: CLOUDINARY_OPTIONS, useFactory: () => environment.cloudinary
        },
        {
            provide: IMAGE_LOADER,
            useFactory: cloudinaryCustomLoaderFactory,
            deps: [CLOUDINARY_OPTIONS]
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
