import { Component, Input, OnChanges, Output, EventEmitter, } from '@angular/core';
import { Attraction, AttractionDataService } from '@volago/attraction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'volago-mobile-info-panel-bottom',
  templateUrl: './mobile-info-panel-bottom.component.html',
})
export class MobileInfoPanelBottomComponent implements OnChanges {

  @Input() attractionId: string;
  @Output() openPanel: EventEmitter<string> = new EventEmitter();

  vm$: Observable<BottomPanelViewModel>;

  constructor(
    private attractionDataService: AttractionDataService,
  ) { }

  ngOnChanges(): void {
    this.vm$ = this.attractionDataService.get(this.attractionId).pipe(
      map(a => <BottomPanelViewModel>{
        attraction: a,
        photoPath: a.photos[0].path
      })
    );
  }

  showInfoPanel(attractionId: string): void {
    this.openPanel.emit(attractionId);
  }

}

interface BottomPanelViewModel {
  attraction: Attraction,
  photoPath: string;
}