import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { AttractionRating } from './rating';
import { DataService } from '@volago/core';

@Injectable({
  providedIn: 'root',
})
export class RatingDataService extends DataService<AttractionRating> {

  constructor(firestore: Firestore) {
    super(firestore, 'ratings');
  }

}
