import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from '@volago/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    FlexLayoutModule,
  ],
  exports: [
    MaterialModule,
    CoreModule,
    FlexLayoutModule,
  ],
})
export class SharedModule { }
