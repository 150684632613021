<h1 mat-dialog-title>Zaloguj się</h1>
<div mat-dialog-content>
  <form [formGroup]="loginForm"class="w-full">

    <mat-form-field class="w-3/4">
      <mat-label for="email">Email</mat-label>
      <input matInput type="text" id="email" formControlName="email">
      <mat-error *ngIf="loginForm.get('email').errors?.required && loginForm.get('email').touched">
        Pole wymagane.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-3/4">
      <mat-label for="password">Password</mat-label>
      <input matInput type="password" id="password" formControlName="password">
      <mat-error *ngIf="loginForm.get('password').errors?.required && loginForm.get('password').touched">
        Pole wymaganes.
      </mat-error>
    </mat-form-field>

    <mat-error *ngIf="loginForm.errors?.loginFailed" class="mt-4">
      Podane dane są nieprawidłowe. Spróbuj ponownie.
    </mat-error>

  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Anuluj</button>
  <button mat-flat-button color="primary" type="submit" [disabled]="loginForm.invalid" (click)="login()">
    Zaloguj
  </button>
</div>