import { DailyOpening, Hours, WeeklyOpening } from '../hours';
import { addDays, isSameDay, getISODay, isEqual, isAfter, isBefore } from 'date-fns';

export class InfoPanelHoursViewModel {
  freeAccess: boolean;
  today: DailyOpening;
  tomorrow: DailyOpening;
  theDayAfterTomorrow: DailyOpening;
  hoursFull: Hours;

  YEAR = 2024;

  constructor(hours: Hours) {
    this.freeAccess = hours.freeAccess;

    const todayIsClosed = this.isClosed(new Date(), hours);
    if (todayIsClosed) {
      this.today = { closed: true } as DailyOpening;
    } else {
      const todayPeriod = this.getPeriod(new Date(), hours);
      this.today = this.selectDay(new Date(), hours.periods[todayPeriod].hours);
    }

    const tomorrowIsClosed = this.isClosed(addDays(new Date(), 1), hours);
    if (tomorrowIsClosed) {
      this.tomorrow = { closed: true } as DailyOpening;
    } else {
      const tomorrowPeriod = this.getPeriod(addDays(new Date(), 1), hours);
      this.tomorrow = this.selectDay(addDays(new Date(), 1), hours.periods[tomorrowPeriod].hours);
    }

    const theDayAfterTomorrowIsClosed = this.isClosed(addDays(new Date(), 2), hours);
    if (theDayAfterTomorrowIsClosed) {
      this.theDayAfterTomorrow = { closed: true } as DailyOpening;
    } else {
      const theDayAfterTomorrowPeriod = this.getPeriod(addDays(new Date(), 2), hours);
      this.theDayAfterTomorrow = this.selectDay(addDays(new Date(), 2), hours.periods[theDayAfterTomorrowPeriod].hours);
    }

    this.hoursFull = {
      ...hours,
    };
  }

  private isClosed(date: Date, hours: Hours): boolean {
    if (!hours.closed) {
      hours.closed = [];
    }

    for (let i = 0; i < hours.closed.length; i++) {
      const element = hours.closed[i];
      if (isSameDay(element, date)) {
        return true;
      }
    }

    return false;
  }

  private getPeriod(date: Date, hours: Hours): number {
    for (let i = 0; i < hours.periods.length; i++) {
      const element = hours.periods[i];
      date.setFullYear(this.YEAR);
      const fromDate = element.from;
      fromDate.setFullYear(this.YEAR);
      const toDate = element.to;
      toDate.setFullYear(this.YEAR);

      element.to.setFullYear(this.YEAR);

      if (isBetween(date, fromDate, toDate)) {
        return i;
      }
    }

    return 0;
  }

  private selectDay(date: Date, week: WeeklyOpening): DailyOpening {
    switch (getISODay(date)) {
      case 1:
        return week.monday;
      case 2:
        return week.tuesday;
      case 3:
        return week.wednesday;
      case 4:
        return week.thursday;
      case 5:
        return week.friday;
      case 6:
        return week.saturday;
      case 7:
        return week.sunday;
      default:
        throw new Error('Invalid day of the week');
    }
  }
}


const isBetween = (date: Date, from: Date, to: Date, inclusivity = '[]') => {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[',
    isAfterEqual = inclusivity[1] === ']';

  return (isBeforeEqual ? (isEqual(from, date) || isBefore(from, date)) : isBefore(from, date)) &&
    (isAfterEqual ? (isEqual(to, date) || isAfter(to, date)) : isAfter(to, date));
};