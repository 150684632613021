import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Hours } from './hours';
import { HoursFirestoreData } from './hours-firestore-data';

@Injectable({
  providedIn: 'root',
})
export class HoursDataService {
  convertTimestampsToDates(hoursData: HoursFirestoreData): Hours {
    return {
      ...hoursData,
      closed: hoursData.closed ? hoursData.closed.map(ts => ts.toDate()) : [],
      irregular: hoursData.irregular ? hoursData.irregular.map(ts => ts.toDate()) : [],
      periods: hoursData.periods ? hoursData.periods.map(period => ({
        ...period,
        from: period.from.toDate(),
        to: period.to.toDate(),
      })) : [],
    };
  }

  convertDatesToTimestamps(hours: Hours): HoursFirestoreData {
    return {
      ...hours,
      closed: hours.closed.map(date => Timestamp.fromDate(date)),
      irregular: hours.irregular.map(date => Timestamp.fromDate(date)),
      periods: hours.periods.map(period => ({
        ...period,
        from: Timestamp.fromDate(period.from),
        to: Timestamp.fromDate(period.to),
      })),
    };
  }
}