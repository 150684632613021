import { Component, OnInit, ViewChild, ElementRef, NgZone, effect, ChangeDetectionStrategy } from '@angular/core';
import { Map, Marker } from 'leaflet';
import { Observable, filter, map } from 'rxjs';
import { MarkerService } from '../../marker/marker.service';
import { GlobalEventsService } from '../../../../shared/global-events/global-events.service';
import { MapService } from './../map.service';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { MediaService } from '@volago/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AttractionDataService } from '@volago/attraction';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'volago-map-desktop',
  templateUrl: './map-desktop.component.html',
  styleUrls: ['./map-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapDesktopComponent implements OnInit {
  markers$: Observable<Marker<unknown>[]>;
  selected$: Observable<string>;
  dontMoveMap = false;
  lang = 'pl-PL';

  @ViewChild('panelWrapper') panelWrapper: ElementRef;

  constructor(
    public mediaService: MediaService,
    public mapService: MapService,
    public markerService: MarkerService,
    public events: GlobalEventsService,
    private route: ActivatedRoute,
    private router: Router,
    private attractionDataService: AttractionDataService,
    private zone: NgZone,
    private translocoService: TranslocoService
  ) {
    this.selected$ = toObservable(this.events.selectedMarker);
    this.lang = this.translocoService.getActiveLang();

    effect(() => {
      const m = this.events.selectedMarker();
      if (m) {
        const marker = this.markerService.getMarker(m);    
        this.mapService.moveMapIfNecessaryDesktop(marker, 0.5);
        router.navigate([this.lang, 'map', m]);
      }
    });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.attractionDataService.get(id).subscribe((to) => {
          this.events.select(to.id);
          if (!this.dontMoveMap) {
            const leftOffset = this.panelWrapper?.nativeElement.offsetLeft;            
            if (leftOffset) {
              this.mapService.setCenterLeftOffset(
                to.coords.latitude,
                to.coords.longitude,
                leftOffset
              );
            }
            this.dontMoveMap = true;
          }
        });
      }
    });

    this.setupBackForwardNavigation();
  }

  private setupBackForwardNavigation(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
      )
      .subscribe((params) => {
        this.events.select(params.id);
      });
  }

  onMapReady(m: Map): void {
    this.mapService.init(m);
  }

  onMapClicked(): void {
    this.events.select(null);
    this.zone.run(() => this.router.navigate([this.lang, 'map']));
  }
}
