import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ScoreCardDialogComponent } from './dialog/score-card-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ScoreCardViewModel } from './score-card-view-model';

@Component({
  selector: 'volago-score-card',
  templateUrl: './score-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreCardComponent implements OnInit {

  colors = ['white', '#EE6055', '#FF9B85', '#FFD97D', '#AAF683', '#60D394'];

  @Input() data: ScoreCardViewModel;
  color: string;

  constructor(
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.color = this.colors[this.data.score];
  }

  openDescriptionDialog(): void {
    this.matDialog.open(ScoreCardDialogComponent, {
      panelClass: 'volago-dialog',
      data: this.data
    });
  }

}