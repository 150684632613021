import { Attraction, Contact, Hours, Pricing, Resources, ScoreInfo } from '@volago/attraction';
import { Photo, Tag } from '@volago/core';

export class AttractionViewModel {
  id: string;
  name: string;
  importance: number;
  regions: string;
  administrative: string;
  photos: Photo[];
  tags: Tag[];
  pricing: Pricing;
  hours: Hours;
  gastronomy: ScoreInfo;
  parking: ScoreInfo;
  payment: ScoreInfo;
  wc: ScoreInfo;
  children: ScoreInfo;
  pets: ScoreInfo;
  accessibility: ScoreInfo;
  howToGet: ScoreInfo;
  contact: Contact;
  resources: Resources;
  summary: string;
  description: string;

  constructor(to: Attraction, regions: string, administrative: string, tags: Tag[]) {
    this.id = to.id;
    this.photos = to.photos;
    this.name = to.name;
    this.importance = to.importance;
    this.regions = regions;
    this.administrative = administrative;
    this.tags = tags.sort((t1, t2) => t1.type - t2.type);
    this.pricing = to.pricing;
    this.hours = to.hours;
    this.gastronomy = to.gastronomy;
    this.parking = to.parking;
    this.payment = to.payment;
    this.wc = to.wc;
    this.children = to.children;
    this.pets = to.pets;
    this.accessibility = to.accessibility;
    this.howToGet = to.howToGet;
    this.contact = to.contact;
    this.resources = to.resources;
    this.summary = to.summary;
    this.description = to.description;
  }
}
