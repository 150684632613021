import { Injectable } from '@angular/core';
import { ResourceType } from './resource-type';
import { Firestore } from '@angular/fire/firestore';
import { DataService } from '@volago/core';

@Injectable({
  providedIn: 'root',
})
export class ResourceTypeDataService extends DataService<ResourceType> {

  constructor(firestore: Firestore) {
    super(firestore, 'resourceTypes');
  }
  
}
