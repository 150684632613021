import { Component, OnInit, NgZone, ChangeDetectionStrategy, effect } from '@angular/core';
import { Map } from 'leaflet';
import { Observable } from 'rxjs';
import { MarkerService } from '../../marker/marker.service';
import { GlobalEventsService } from '../../../../shared/global-events/global-events.service';
import { MapService } from './../map.service';
import { ActivatedRoute, Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';
import { AttractionDataService } from '@volago/attraction';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'volago-map-mobile',
  templateUrl: './map-mobile.component.html',
  styleUrls: ['./map-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMobileComponent implements OnInit {
  selected$: Observable<string>;
  mobileExpanded: boolean;
  dontMoveMap = false;
  lang = 'pl-PL'

  constructor(
    public markerService: MarkerService,
    private mapService: MapService,
    private events: GlobalEventsService,
    private route: ActivatedRoute,
    private router: Router,
    private attractionDataService: AttractionDataService,
    private zone: NgZone,
    private translocoService: TranslocoService
  ) {
    this.selected$ = toObservable(this.events.selectedMarker);
    this.lang = this.translocoService.getActiveLang();

    effect(() => {
      const m = this.events.selectedMarker();
      if (m) {
        const marker = this.markerService.getMarker(m);
        this.mapService.moveMapIfNecessary(marker);
      }
    });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.attractionDataService.get(id).subscribe((to) => {
          this.mobileExpanded = true;
          this.events.select(id);
          if (!this.dontMoveMap) {
            this.mapService.setCenter(to.coords.latitude, to.coords.longitude);
            this.dontMoveMap = true;
          }
        });
      }
    });
  }

  closeMobileInfoPanel(): void {
    this.zone.run(() => this.router.navigate([this.lang ,'map']));
    this.mobileExpanded = false;
  }

  openMobileInfoPanel(id: string): void {
    this.zone.run(() => this.router.navigate([this.lang, 'map', id]));
    this.mobileExpanded = true;
  }

  onMapReady(map: Map): void {
    this.mapService.init(map);
  }

  onMapClicked(): void {
    this.events.select(null);
  }
}
