import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyOpening } from '../../hours';

@Component({
  selector: 'volago-attraction-hours-row',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hours-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoursRowComponent {

  caption = input.required<string>();
  hours = input.required<DailyOpening>();

}
