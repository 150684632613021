<div class="relative">

  @if (data().officialUrl) {
  <a class="absolute right-0 p-2" target="_blank" [href]="data().officialUrl">
    <span class="material-symbols-outlined"> open_in_new </span>
  </a>
  }

  <div class="text-center text-lg py-4">
    @for (section of data().sections; track $index) {
    <div>
      <span class="text-gray-400 bold mr-2">{{section.name}}</span>
      @if (section.free) {
      <span>bezpłatnie</span>
      } @else {
      <span class="mr-1 text-green-600">{{section.freeUnder}} lat</span>
      <span class="mr-1 text-gray-400">/</span>
      <span class="mr-1 text-yellow-500">{{section.reduced}} {{currency()}}</span>
      <span class="mr-1 text-gray-400">/</span>
      <span class="mr-1 text-yellow-500">{{section.full}} {{currency()}}</span>
      <span class="mr-1 text-gray-400">/</span>
      @if (section.family) {
      <span class="text-yellow-500">{{section.family}} {{currency()}}</span>
      }
      @else {
      <span class="text-yellow-500">-</span>
      }
      }
    </div>
    }
  </div>

  @if (data().combinedInfo) {
  <div class="p-2">{{data().combinedInfo}}</div>
  }

</div>