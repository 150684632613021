import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeManagerService, Themes } from './theme-manager.service';

@Component({
  selector: 'volago-theme-picker',
  standalone: true,
  imports: [CommonModule, NgIf, MatIconModule, MatButtonModule],
  templateUrl: './theme-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemePickerComponent {

  themeService = inject(ThemeManagerService);
  currentTheme = toSignal(this.themeService.theme$);

  toggleTheme(theme: Themes | undefined): void {
    if (!theme) {
      return;
    }
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    this.themeService.switchTheme(newTheme);
  }

}
