<ng-container *ngIf="mediaService.isMobile()">
  <volago-map-mobile></volago-map-mobile>
</ng-container>

<ng-container *ngIf="!mediaService.isMobile()">
  <volago-map-desktop></volago-map-desktop>
</ng-container>

<div class="fixed top-3 left-3 z-[1000] flex gap-3">
  <volago-user-menu />
  <volago-theme-picker />
  <volago-language-selector />
</div>