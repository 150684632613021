import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { TranslocoRootModule } from '@volago/core';
import { Hours } from '../hours';
import { InfoPanelHoursViewModel } from './info-panel-hours-view-model';
import { MatDialog } from '@angular/material/dialog';
import { HoursDialogComponent } from './hours-dialog/hours-dialog.component';
import { HoursRowComponent } from './hours-row/hours-row.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'volago-attraction-hours',
  standalone: true,
  imports: [CommonModule, TranslocoRootModule, HoursRowComponent, MatButtonModule],
  templateUrl: './hours.component.html',
})
export class HoursComponent {

  #matDialog = inject(MatDialog);

  data = input.required<Hours>();
  vm = computed(() => new InfoPanelHoursViewModel(this.data()));

  openHoursDialog(): void {
    this.#matDialog.open(HoursDialogComponent, {
      panelClass: 'volago-dialog',
      data: this.vm().hoursFull
    });
  }

}
