<div *ngIf="photosInternal.length > 0" class="mini-gallery">
  <div class="image-panel relative cursor-pointer">

    <img [ngSrc]="selectedImg" fill [loaderParams]="{crop: 'fill', width: 600, height: 300}" (click)="openGallery()"
      (keydown.enter)="openGallery()" class="main-photo" priority placeholder alt="main photo" tabindex="0"
      role="button" />

    <div *ngIf="photosInternal.length > 1" class="gallery-buttons-panel">

      <div class="flex">
        <div class="flex-none w-14 h-14">
          <!-- This item will not grow -->
          <button (click)="movePrev()" mat-mini-fab color="primary">
            <span class="material-symbols-outlined">arrow_left</span>
          </button>
        </div>
        <div class="flex-grow self-center">
          <!-- This item will grow -->
          <ng-container *ngFor="let p of photosInternal; index as i">
            <span *ngIf="i === current">
              <img src="assets/images/gallery/selected_marble.png" class="marble" alt="selected photo indicator" />
            </span>
            <span *ngIf="i !== current">
              <img src="assets/images/gallery/marble.png" class="marble" alt="photo indicator" />
            </span>
          </ng-container>
        </div>
        <div class="flex-none w-14 h-14">
          <!-- This item will not grow -->
          <button (click)="moveNext()" mat-mini-fab color="primary">
            <span class="material-symbols-outlined">arrow_right</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full text-right">
    @if (selected.copyrights && !selected.copyrightsUrl) {
    <span class="pr-2"> &copy; {{ selected.copyrights }} </span>
    }
    @if (selected.copyrightsUrl) {
    <span class="pr-2">
      <a href="{{ selected.copyrightsUrl }}" target="_blank" class="copyright-link mat-caption">
        &copy; {{ selected.copyrights }}
      </a>
    </span>
    }
  </div>

</div>