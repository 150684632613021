import { Injectable, inject } from '@angular/core';
import { Observable, Subject, fromEvent, map, merge, of, shareReplay, startWith, tap } from 'rxjs';
import { LocalStorageService } from '@volago/core';

export type Themes = 'light' | 'dark';
export type ThemeUrls = `${Themes}-theme.css`

@Injectable({
  providedIn: 'root'
})
export class ThemeManagerService {

  PREFFERED_THEME_KEY = 'preferredTheme';
  // Based on https://courses.decodedfrontend.io/courses/take/angular-material-theming-workshop/texts/46128788-removing-warning-about-missed-angular-material-core-theme-which-is-not
  // Detect which theme user prefers
  #preferenceQuery = matchMedia(`(prefers-color-scheme: light)`);
  #themeSwitcher = new Subject<Themes>();
  // Listen to the preferens changes
  #userEnvThemePreference = fromEvent<MediaQueryList>(this.#preferenceQuery, 'change').pipe(
    startWith(this.#preferenceQuery),
    map(this.resolvePreferredTheme.bind(this)),
  );

  theme$ = merge(
    this.#userEnvThemePreference,
    this.#themeSwitcher
  ).pipe(
    // Load the corresponding css file (Theme)
    tap(theme => this.loadTheme(this.getThemeLinkElement(), theme)),
    shareReplay()
  );

  localStorageService = inject(LocalStorageService);

  load(): Observable<boolean> {
    this.theme$.subscribe();
    return of(true);
  }

  switchTheme(themeName: Themes) {
    this.localStorageService.set(this.PREFFERED_THEME_KEY, themeName);
    this.#themeSwitcher.next(themeName);
  }

  detectThemeAutomatically() {
    this.localStorageService.remove(this.PREFFERED_THEME_KEY);
    this.#themeSwitcher.next(this.resolvePreferredTheme(this.#preferenceQuery))
  }

  resolvePreferredTheme(query: MediaQueryList): Themes {
    const preferredTheme = this.localStorageService.get(this.PREFFERED_THEME_KEY);
    if (preferredTheme) {
      return preferredTheme as Themes;
    }
    return query.matches ? 'light' : 'dark';
  }

  private getThemeLinkElement(): HTMLLinkElement {
    const existingLinkEl = document.head.querySelector<HTMLLinkElement>(`#appTheme`);
    if (existingLinkEl) {
      return existingLinkEl;
    }
    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.setAttribute('id', 'appTheme');

    const lastStyleSheet = document.head.querySelector(`link[rel="stylesheet"]`);
    if (lastStyleSheet) {
      lastStyleSheet.after(linkEl);
    }

    return linkEl;
  }

  private loadTheme(linkEl: HTMLLinkElement, theme: Themes) {
    linkEl.setAttribute('href', this.resolveThemeUrl(theme));
  }

  private resolveThemeUrl(themeName: Themes): ThemeUrls {
    return `${themeName}-theme.css`;
  }
}

