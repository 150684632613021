import { Component, Signal, inject, signal, input, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Auth, User, user } from '@angular/fire/auth';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AttractionRating, EMPTY_ATTRACTION_RATING } from './rating';
import { RatingDataService } from './rating-data.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'volago-attraction-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rating.component.html',
  styles: [`
    .heart { color: var(--color-special-heart); }
    .tour { color: var(--color-special-tour); }
    .star { color: var(--color-special-star); }
  `]
})
export class RatingComponent {

  private ratingDataService = inject(RatingDataService);
  private auth = inject(Auth);

  attractionId = input.required<string>();
  attractionId$ = toObservable(this.attractionId);  
  
  user$ = user(this.auth);
  user = toSignal(this.user$);
  isLogged = toSignal(this.user$) as Signal<User | null>;

  attractionRating = signal<AttractionRating>(EMPTY_ATTRACTION_RATING);
  attractionRatingId = computed(() => this.attractionId() + '_' + this.user()?.uid);

  constructor() {
    combineLatest([this.attractionId$, this.user$]).pipe(
      untilDestroyed(this),
      filter(([attractionId, user]) => !!attractionId && !!user),
      map(([attractionId, ]) => attractionId),
      tap(() => this.attractionRating.set(EMPTY_ATTRACTION_RATING)),
      switchMap(() => this.ratingDataService.get(this.attractionRatingId()))
    ).subscribe(attractionRating => {
      if (attractionRating) {
        this.attractionRating.set(attractionRating);
      }
    });
  }

  toggleFavorite() {
    this.attractionRating.set({
      ...this.attractionRating(),
      favorite: !this.attractionRating().favorite,
    });
    this.save();
  }

  toggleWantToVisit() {
    this.attractionRating.set({
      ...this.attractionRating(),
      wantToVisit: !this.attractionRating().wantToVisit,
    });
    this.save();
  }

  toggleStars(stars: number) {
    const startsToSet = stars === this.attractionRating().stars ? 0 : stars;

    this.attractionRating.set({
      ...this.attractionRating(),
      stars: startsToSet,
    });
    this.save();
  }

  private save(): void {
    if (!this.attractionRating().id) {
      this.attractionRating.set({
        ...this.attractionRating(),
        id: this.attractionRatingId(),
        userId: this.user()?.uid || '',
        attractionId: this.attractionId()
      });
      this.ratingDataService.add(this.attractionRating());
    }
    else {
      this.ratingDataService.save(this.attractionRating());
    }

  }

}
