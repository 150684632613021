import { Injectable } from '@angular/core';
import { Map } from 'leaflet';
import { VolagoMarker } from '@volago/core';

@Injectable({
  providedIn: 'root',
})
export class MapService {

  map: Map;

  init(map: Map): void {
    this.map = map;
  }

  getMap(): Map {
    return this.map;
  }

  setCenter(lat: number, lng: number): void {
    this.map.setView({ lat, lng }, this.map.getZoom(), { animate: true });
  }  

  setCenterLeftOffset(lat: number, lng: number, offsetLeft: number): void {
    const mapSize = this.map.getSize();
    const xMap = mapSize.x;

    const bounds = this.map.getBounds();
    const west = bounds.getWest();
    const east = bounds.getEast();

    const q2 = ((xMap - offsetLeft) * (east - west)) / xMap;
    const newCenter = { lat, lng: lng + q2 / 2 };

    this.map.setView(newCenter, this.map.getZoom(), { animate: true });
  }

  moveMapIfNecessary(m: VolagoMarker): void {
    const mapSize = this.map.getSize();
    const yMap = mapSize.y;
    const mapThreshold = (yMap - 180) / yMap;

    const bounds = this.map.getBounds();
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const yGeo = north - south; 
    const geoThreshold = (north - m.coords.latitude) / yGeo;

    if (mapThreshold < geoThreshold) {
      const newCenter = this.map.getCenter();
      const mapThresholdToGeo = north - mapThreshold * yGeo;
      const moveBy = mapThresholdToGeo - m.coords.latitude;
      newCenter.lat -= moveBy;
      this.map.setView(newCenter, this.map.getZoom(), { animate: true });
    }
  }

  moveMapIfNecessaryDesktop(m: VolagoMarker, panelPercent: number): void {
    const mapSize = this.map.getSize();
    const xMap = mapSize.x;
    const mapThreshold = (xMap - xMap * panelPercent) / xMap + 0.03;

    const bounds = this.map.getBounds();
    const east = bounds.getEast();
    const west = bounds.getWest();
    const xGeo = east - west;
    const geoThreshold = (east - m.coords.longitude) / xGeo;

    if (mapThreshold > geoThreshold) {
      const newCenter = this.map.getCenter();
      const mapThresholdToGeo = (mapThreshold - geoThreshold) * xGeo;
      const moveBy = mapThresholdToGeo;
      newCenter.lng += moveBy;
      this.map.setView(newCenter, this.map.getZoom(), { animate: true });
    }
  }

}
