<span class="material-symbols-outlined">star</span>
<span class="material-symbols-outlined">tour</span>

@if (isLogged()) {
<div class="p-2 flex justify-end items-center">

  <div class="mr-4">
    @for (item of [1, 2, 3, 4, 5]; track item) {
    <span [ngClass]="{'filled star': attractionRating().stars >= item}"
      class="material-symbols-outlined text-3xl cursor-pointer mr-1" (click)="toggleStars(item)"
      (keydown.enter)="toggleStars(item)" tabindex="0" role="button"
      [attr.aria-label]="'Set ' + item + ' stars'">star</span>
    }
  </div>

  <span [ngClass]="{'filled heart': attractionRating().favorite}"
    class="material-symbols-outlined text-3xl cursor-pointer mr-4" (click)="toggleFavorite()"
    (keydown.enter)="toggleFavorite()" tabindex="0" role="button"
    [attr.aria-label]="attractionRating().favorite ? 'Remove from favorites' : 'Add to favorites'">favorite</span>

  <span [ngClass]="{'filled tour': attractionRating().wantToVisit}"
    class="material-symbols-outlined text-3xl cursor-pointer" (click)="toggleWantToVisit()"
    (keydown.enter)="toggleWantToVisit()" tabindex="0" role="button"
    [attr.aria-label]="attractionRating().wantToVisit ? 'Remove from want to visit' : 'Add to want to visit'">tour</span>

</div>
}