import { Component, Input } from '@angular/core';
import { Contact } from '@volago/attraction';

@Component({
  selector: 'volago-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent {
  
  @Input() data: Contact;
  
}
