<ng-container *transloco="let t">
  <div class="p-2">

    <div class="mb-2">{{ t('attraction.links')}}</div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
      @for (r of vm(); track r.url) {
      <a [href]="r.url" target="_blank" class="link space-above flex py-2">
        <span class="material-symbols-outlined mr-4">{{r.icon}}</span>
        <span>{{r.description}}</span>
      </a>
      }
    </div>
  </div>
</ng-container>