import { Component, OnInit, Input, ChangeDetectionStrategy, } from '@angular/core';
import { Image, PlainGalleryConfig, ModalGalleryService, ModalGalleryRef, PlainLibConfig } from '@ks89/angular-modal-gallery';
import { sortBy } from 'lodash-es';
import { APPCONFIG } from '../../../app.config';
import { Photo } from '@volago/core';

@Component({
  selector: 'volago-mini-gallery',
  templateUrl: './mini-gallery.component.html',
  styleUrls: ['./mini-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniGalleryComponent implements OnInit {
  current = 0;
  appConfig = APPCONFIG;
  selectedImg: string;

  photosInternal: Photo[] = [];
  @Input()
  set photos(value: Photo[]) {
    if (value) {
      this.photosInternal = sortBy(value, (x) => x.order);
      if (value && value.length > 0) {
        this.images = value.map(
          (p, index) =>
          ({
            id: index,
            modal: {
              img: this.appConfig.cloudinary.imageBaseUrl + p.path,
              description: p.description,
              title: p.description,
            },
          } as Image)
        );
      }
      this.refreshGallery();
    }
  }

  libConfigPlainGallery: PlainLibConfig = {
    plainGalleryConfig: {
      // your custom plain gallery configuration here
    } as PlainGalleryConfig,
  };

  selected: Photo;
  photosCount: number;
  images: Image[] = [];

  constructor(
    private modalGalleryService: ModalGalleryService,
  ) { }

  onShow(id: number, index: number, images: Image[] = this.images): void {
    this.modalGalleryService.open({
      id,
      images,
      currentImage: images[index],
    }) as ModalGalleryRef;
  }

  ngOnInit(): void {
    this.refreshGallery();
  }

  private refreshGallery(): void {
    this.current = 0;
    this.selected = this.photosInternal[this.current];
    this.selectedImg = this.getMinPhoto();
    this.photosCount = this.photosInternal.length;
  }

  openGallery(): void {
    this.modalGalleryService.open({
      id: 1,
      images: this.images,
      currentImage: this.images[this.current],
    }) as ModalGalleryRef;
  }

  moveNext(): void {
    this.current = (this.current + 1) % this.photosInternal.length;
    this.selected = this.photosInternal[this.current];
    this.selectedImg = this.getMinPhoto();
  }

  movePrev(): void {
    this.current =
      this.current - 1 < 0 ? this.photosInternal.length - 1 : this.current - 1;
    this.selected = this.photosInternal[this.current];
    this.selectedImg = this.getMinPhoto();
  }

  private getMinPhoto(): string {
    return this.selected.path;
  }
}
