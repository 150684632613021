<volago-dialog title="Godziny otwarcia">
  <div class="volago-dialog-header-actions"></div>
  <div class="volago-dialog-content">

    @for (p of hours.periods; track $index) {
    <div class="mb-8">
      <h3>{{ p.from.toLocaleDateString() }} - {{ p.to.toLocaleDateString()}}</h3>
      <table>
        <thead>
          <th>Dzień tygodnia</th>
          <th>Otwarcie</th>
          <th>Zamknięcie</th>
          <th>Ostatnie wejście</th>
        </thead>
        <tbody>
          <tr>
            <td>poniedziałek</td>
            <ng-container *ngIf="!p.hours.monday.closed else dayClosed">
              <td>{{p.hours.monday.from}}</td>
              <td>{{p.hours.monday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>wtorek</td>
            <ng-container *ngIf="!p.hours.tuesday.closed else dayClosed">
              <td>{{p.hours.tuesday.from}}</td>
              <td>{{p.hours.tuesday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>środa</td>
            <ng-container *ngIf="!p.hours.wednesday.closed else dayClosed">
              <td>{{p.hours.wednesday.from}}</td>
              <td>{{p.hours.wednesday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>czwartek</td>
            <ng-container *ngIf="!p.hours.thursday.closed else dayClosed">
              <td>{{p.hours.thursday.from}}</td>
              <td>{{p.hours.thursday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>piątek</td>
            <ng-container *ngIf="!p.hours.friday.closed else dayClosed">
              <td>{{p.hours.friday.from}}</td>
              <td>{{p.hours.friday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>sobota</td>
            <ng-container *ngIf="!p.hours.saturday.closed else dayClosed">
              <td>{{p.hours.saturday.from}}</td>
              <td>{{p.hours.saturday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>niedziela</td>
            <ng-container *ngIf="!p.hours.sunday.closed else dayClosed">
              <td>{{p.hours.sunday.from}}</td>
              <td>{{p.hours.sunday.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>święta</td>
            <ng-container *ngIf="!p.hours.holidays.closed else dayClosed">
              <td>{{p.hours.holidays.from}}</td>
              <td>{{p.hours.holidays.to}}</td>
              <td>{{p.lastEntranceMin}}</td>
            </ng-container>
          </tr>
        </tbody>
        <ng-template #dayClosed>
          <tr>
            <td colspan="3">nieczynne</td>
          </tr>
        </ng-template>
      </table>
    </div>
    }
    <div *ngIf="hours.closed && hours.closed.length > 0" class="closed-dates">
      <h3>Nieczynne</h3>
      <div *ngFor="let c of hours.closed">
        {{ c.toLocaleDateString('D MMMM') }}
      </div>
    </div>

    <div>
      <a target="_blank" [href]="hours.officialUrl">Godziny otwarcia na stronie obiektu</a>
    </div>

  </div>

  <div class="volago-dialog-actions">
    <button mat-button mat-dialog-close (click)="close()">Zamknij</button>
  </div>
</volago-dialog>