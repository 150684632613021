import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  default = 'Volago - przewodnik turystyczny';

  constructor(
    private titleService: Title
  ) { }

  setTitle(toName: string): void {
    this.titleService.setTitle(`${toName} - ${this.default}`)
  }

  setDefaultTitle(): void {
    this.titleService.setTitle(this.default);
  }
}
