<ng-container *transloco="let t">
  <div class="p-2">

    @if (data().officialUrl) {
    <a class="absolute right-0 p-2 text-inherit" target="_blank" [href]="data().officialUrl">
      <span class="material-symbols-outlined"> open_in_new </span>
    </a>
    }

    <div class="py-4">
      @if (data().freeAccess) {
      <div>{{t('attraction.hours.free-access')}}</div>
      }

      @if (data().estimatedVisitTime) {
      <div class="py-2">
        {{t('attraction.hours.estimated-time')}} <b>{{data().estimatedVisitTime}}</b>
      </div>
      }

    </div>

    <volago-attraction-hours-row caption="dziś" [hours]="vm().today" />
    <volago-attraction-hours-row caption="jutro" [hours]="vm().tomorrow" />
    <volago-attraction-hours-row caption="pojutrze" [hours]="vm().theDayAfterTomorrow" />

    <button mat-button (click)="openHoursDialog()">pełne godziny otwarcia</button>

  </div>
</ng-container>