import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AdministrativeService, RegionService, TagDataService } from '@volago/core';
import { Observable, switchMap, mergeMap, map, tap, BehaviorSubject } from 'rxjs';
import { TitleService } from '../../shared/service/title.service';
import { AttractionViewModel } from './attraction-view-model';
import { AttractionDataService } from '@volago/attraction';

@Component({
  selector: 'volago-info-panel',
  templateUrl: './info-panel.component.html',
  styles: ['::ng-deep .summary > p {margin-bottom: 0.5rem}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent implements OnDestroy {

  @Input() set attractionId$(attractionId$: Observable<string>) {
    attractionId$.subscribe((x) => {
      this.attIdInternal$.next(x);
    });
  }

  @Output() closed = new EventEmitter();

  attraction$: Observable<AttractionViewModel>;
  attIdInternal$ = new BehaviorSubject<string>(null);

  constructor(
    private attractionDataService: AttractionDataService,
    private titleService: TitleService,
    private regionService: RegionService,
    private administrativeService: AdministrativeService,
    private tagDataService: TagDataService,    
  ) {
    this.attraction$ = this.attIdInternal$.pipe(
      switchMap(aId => this.attractionDataService.get(aId).pipe(
        mergeMap(y => this.tagDataService.getMany(y.tags).pipe(
          map(r => {
            const regions = y.regions?.map(rId => this.regionService.getRegionPath(rId)).join(', ') || null;
            const adm = this.administrativeService.getAdministrativePath(y.administrative);
            return new AttractionViewModel(y, regions, adm, r);
          })
        ))
      )),
      tap(avm => this.titleService.setTitle(avm.name)),
    );
  }

  ngOnDestroy(): void {
    this.titleService.setDefaultTitle();
  }
}