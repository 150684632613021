import { take } from 'rxjs/operators';
import { ResourceType } from './resource-type';
import { ResourceTypeDataService } from './resource-type-data.service';
import { Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResourceTypeService {

  private resourceTypes = signal<ResourceType[]>([]);
  
  constructor(private resourceTypeDataService: ResourceTypeDataService) {
    this.resourceTypeDataService.getAll().pipe(take(1)).subscribe((ts) => {
      this.resourceTypes.set(ts);
    });
  }

  getAllResouceTypes(): Signal<ResourceType[]> {
    return this.resourceTypes;
  }

  getResuorceTypeIcon(type: string): string {
    return this.resourceTypes().filter(t => t.id === type)[0]?.icon || '';
  }

}
